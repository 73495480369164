import React from 'react'
import Button from '../../atoms/Button/Button'
import RegisterModal from '../../organisms/register-modal/register-modal'
import './modal-user-migrated.scss'

const ModalUserMigrated = ({
	dataUserMigrated,
	hideModal,
	onUpdateInformation,
}) => {
	const { name = '' } = dataUserMigrated

	return (
		<RegisterModal
			onClose={hideModal}
			title={`${name} ya haces parte de nuestra comunidad`}
			description={
				<>
					Queremos conocerte más para entregarte cada día más experiencias, por
					eso es importante tener tus datos completos.
				</>
			}
			iconCheck
		>
			<Button
				type='button'
				classname='f-button f-button--primary f-button-update-user'
				text='ACTUALIZA TUS DATOS'
				onClickAction={onUpdateInformation}
			/>
		</RegisterModal>
	)
}

export default ModalUserMigrated
