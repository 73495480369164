import React from 'react'
import AlertMessagge from '../../../components/molecules/alertMessagge/alertMessagge'
import Button from '../../../components/atoms/Button/Button'
import './generic-modal-alert.scss'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import useHaveSamples from '../../../hooks/useHaveSamples'
import { siteUrl } from "../../../state/utils/site-url"
import useHomeLinkMode from "../../../hooks/useHomeLinkMode"

const GenericModalAlert = ({
	handleAction,
	icon,
	title,
	description,
	descriptionRender = null,
	type,
	className = '',
	modifier
}) => {

	const { haveSamples } = useHaveSamples();
	const { homeLink } = useHomeLinkMode();
	const isCO =  process.env.LOCALE === "es-CO"

	return (
		<LayoutModal>
			<div className={`f-generic-alert-wrapper ${className}`}>
				<span
					className='f-generic-alert-close'
					onClick={() => {
						handleAction(false)
					}}
				>
					X
				</span>
				<AlertMessagge
					icon={icon ? <img src={icon} alt='icon' /> : null}
					messagge={title}
					description={description}
					descriptionRender={descriptionRender}
					type={type}
					button={
							modifier === "prospect" ? (
								<div className='f-generic-alert-redirection__buttons'>
								{
									haveSamples ?  (
										<>
											<Button
												type="button"
												onClickAction={() => {
												window.location.href = isCO ? homeLink + "muestra-gratis/" : homeLink + "pide-tu-muestra/"
												}}
												text="PEDIR MUESTRA"
												classname="f-button f-button--secondary"
											/>
											<Button
												type="button"
												onClickAction={() => {
												window.location.href = siteUrl
												}}
												text="VOLVER AL INICIO"
												classname="f-button f-button--primary"
											/>
										</>
									) : (
										<>
											<Button
												type="button"
												onClickAction={() => {
												window.location.href = homeLink + "productos/"
												}}
												text="VER PRODUCTOS"
												classname="f-button f-button--secondary"
											/>
											<Button
													type="button"
													onClickAction={() => {
													window.location.href = siteUrl
													}}
													text="VOLVER AL INICIO"
													classname="f-button f-button--primary"
												/>
										</>
									)
								}
								</div>
							) :
							<Button
							type='button'
							text='ACEPTAR'
							onClickAction={() => {
								handleAction(false)
							}}
							classname='f-button f-button--primary'
						/>
					}
				/>
			</div>
		</LayoutModal>
	)
}

export default GenericModalAlert
