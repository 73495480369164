import React, { useState } from 'react'
import * as Icons from '@material-ui/icons'
import './input-material.scss'
import Alert from '../alert/alert'

const InputMaterial = props => {
	const {
		label,
		value,
		name,
		errors,
		messageError,
		register,
		change,
		autoComplete = false,
		classes,
		id,
		disabled = false,
		type,
		blurEvent,
		isNumberAddress = false,
		characterAddress,
		onClick,
		showMessageError = true,
		autoFocus = false
	} = props

	const [typeChange, settypeChange] = useState('password')

	return (
		<div
			className={
				`f-input-material ${classes} ` + (disabled ? 'f-disabled-input' : '')
			}
		>
			<label
				className={`${
					errors[name] || messageError
						? 'pure-material-textfield-outlined pure-material-textfield-outlined--error'
						: 'pure-material-textfield-outlined'
				}`}
			>
				{isNumberAddress ?
					<div className='pure-material-textfield-outlined-container'>
						<span className='pure-material-textfield-outlined-span'>{characterAddress}</span><input
							id={id}
							onClick={onClick}
							onBlur={blurEvent}
							autoComplete={autoComplete ? 'on' : 'off'}
							onChange={change}
							ref={register}
							placeholder=' '
							name={name}
							value={value}
							type={type === 'password' ? typeChange : type}
						/>
						<span>{label}</span>
					</div>
				:
				<><input
					id={id}
					onClick={onClick}
					onBlur={blurEvent}
					autoComplete={autoComplete ? 'on' : 'off'}
					onChange={change}
					ref={register}
					placeholder=' '
					name={name}
					value={value}
					type={type === 'password' ? typeChange : type}
					autoFocus={autoFocus}
				/>
				<span>{label}</span></>}
			</label>
			{type === 'password' ? (
				typeChange === 'password' ? (
					<Icons.Visibility
						className='f-btn-show-password'
						onClick={() => settypeChange('text')}
					/>
				) : (
					<Icons.VisibilityOff
						className='f-btn-show-password'
						onClick={() => settypeChange('password')}
					/>
				)
			) : null}
			{errors[name] && showMessageError && (
				<Alert alertType='error' description={errors[name].message} />
			)}
			{!errors[name] && showMessageError && messageError && (
				<Alert alertType='error' description={messageError} />
			)}
		</div>
	)
}

export default InputMaterial
