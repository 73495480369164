import SecureStorage from 'secure-web-storage'
const CryptoJS = require('crypto-js')

const KEY = 'UHJhZ21hMjAyMCs='
const _storage = typeof window !== 'undefined' ? localStorage : {}
export const secureStorage = new SecureStorage(_storage, {
	hash: function hash(key) {
		key = CryptoJS.SHA256(key, KEY)

		return key.toString()
	},
	encrypt: function encrypt(data) {
		data = CryptoJS.AES.encrypt(data, KEY)

		data = data.toString()

		return data
	},
	decrypt: function decrypt(data) {
		data = CryptoJS.AES.decrypt(data, KEY)

		data = data.toString(CryptoJS.enc.Utf8)

		return data
	},
})

// export default secureStorage;
