import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import celularIconWhite from '../../../assets/images/icon-celular-white.svg'
import icon from '../../../assets/images/successfulRegister.png'
import useMobileMode from '../../../hooks/useMobileMode'
import Logo from '../../molecules/logo/logo'
import Icon from '../../atoms/Icon/Icon'
import FontButtons from '../../atoms/font-buttons/font-buttons'
import LayoutPortal from '../../organisms/layout-portal/layout-portal'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'


import './register-modal.scss'
import { convertText } from '../../../utils/convertFirstCharacterToUppercase'

const RegisterModal = ({ title, subtitle, description, onClose, children, iconCheck }) => {
	const result = useStaticQuery(graphql`
		query InfoFooterModal {
			allContentfulTenaHome {
				nodes {
					footerTituloLineaDeAtencion
					footerNumeroLineaDeAtencion
					footerLineaDeAtencionRegistro
					node_locale
				}
			}
		}
	`)

	const { isMobile } = useMobileMode()
	const locale = process.env.LOCALE
	const {
		footerTituloLineaDeAtencion,
		footerNumeroLineaDeAtencion,
		footerLineaDeAtencionRegistro,
	} = result.allContentfulTenaHome.nodes.filter(
		node => node.node_locale === locale
	)[0]

	React.useEffect(() => {
		const body = document.getElementsByTagName('body')[0]
		body.classList.add('overflow-body-hidden')

		return () => {
			body.classList.remove('overflow-body-hidden')
		}
	})
	const dataLayerRegistroSiguiente = (evento, categoria, mensaje) => {
		let location = '';
		if (typeof window !== 'undefined') {
			location = window.location.href
		}
		pushDataLayerEvent({
			event: evento,
			category: categoria,
			action: 'view',
			label: mensaje,
			location: location,
		})
	}

	React.useEffect(() => {
		if (title === 'Te damos la bienvenida a TENA') {
			dataLayerRegistroSiguiente('Interacciones - Registro', 'registro completado', 'Haz completado tu registro')
			pushDataLayerEvent({
				event: 'sign_up',
				method: 'form'
			})
		} else if (title === 'Ya actualizaste los datos de tu cuenta TENA') {
			dataLayerRegistroSiguiente('Interacciones - Actualizar', 'datos actualizados', 'Ya actualizaste los datos de tu cuenta TENA')
		}
	}, [])
	

	return (
		<LayoutPortal>
			<section className='f-modal-register'>
				<div className='f-modal-register__container'>
					<header className='f-header-wrapper'>
						<div className='f-header-container'>
							<div className='f-header-logo'>
								<Logo />
							</div>
							<div className='f-header-wrapper-fullpage'>
								<div className='f-button-back' onClick={onClose}>
									<div className='f-btn-return'>
										<Icon
											icon='icon-arrow-left white'
											size='3'
											tagtype='span'
										/>
									</div>
									<p>{convertText('VOLVER AL INICIO')}</p>
								</div>
								<FontButtons />
							</div>
						</div>
					</header>
					<div className='f-modal-register__content-wrapper' onClick={onClose}>
						<div
							className='f-modal-register__content-wrapper__card'
							onClick={e => e.stopPropagation()}
						>
							{iconCheck  ?  <img src={icon} alt='Successful Register' /> : <></>}
							{title && <h1 className='f-card-modal__title'>{title}</h1>}
							{subtitle && (
								<h2 className='f-card-modal__subtitle'>{subtitle}</h2>
							)}
							{description && (
								<p className='f-card-modal__description'>{description}</p>
							)}
							{children}
						</div>
					</div>

					<div className='f-modal-register__footer'>
						<div className='f-phone-info-wrapper'>
							{locale !== 'es-BO' ? (
								<>
									<img
										src={celularIconWhite}
										alt='imagen de linea de atencion'
									/>
									<div>
										<p>
											{isMobile
												? footerTituloLineaDeAtencion
												: footerLineaDeAtencionRegistro}
										</p>
										<a
											href={`tel://${footerNumeroLineaDeAtencion}`}
											className='f-btn-nav-type'
											rel='nofollow'
										>
											{footerNumeroLineaDeAtencion}
										</a>
									</div>
								</>
							) : null}
						</div>
					</div>
				</div>
			</section>
		</LayoutPortal>
	)
}

export default RegisterModal
