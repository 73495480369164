import { graphql, useStaticQuery } from 'gatsby'

export default function useBrowserMode() {
	const result = useStaticQuery(
		graphql`
			{
				allContentfulTenaHome {
					nodes {
						node_locale
						tieneTiendas
						tieneMuestras
					}
				}
			}
		`
	)

	const filterContentForLocale = locale =>
		result.allContentfulTenaHome.nodes.filter(
			node => node.node_locale === locale
		)[0]

	const contentCountry = filterContentForLocale(process.env.LOCALE)

	return {
		haveSamples: contentCountry.tieneMuestras,
		haveShops: contentCountry.tieneTiendas,
	}
}
