import React from 'react'
import useBrowserMode from './../../../hooks/useBrowserMode'
import { MIN_FONT, MAX_FONT } from '../../../state/constants/appConstants'
import './font-buttons.scss'

const FontButtons = () => {
	const { isBrowser } = useBrowserMode()
	const html = isBrowser && document.getElementsByTagName('html')[0]
	const [currentFontSize, setCurrentFontsize] = React.useState(MIN_FONT)

	const handleChangeSize = _newFontSize => {
		setCurrentFontsize(_newFontSize)
		html.style.fontSize = `${_newFontSize}px`
		localStorage.setItem('fontSize', _newFontSize)
	}

	React.useEffect(() => {
		const storageFontSize = localStorage.getItem('fontSize')
		if (storageFontSize) {
			setCurrentFontsize(Number(storageFontSize))
			html.style.fontSize = `${Number(storageFontSize)}px`
		}
	}, [])

	return (
		<div className='f-btn-container'>
			<button
				onClick={() => {
					handleChangeSize(currentFontSize - 2)
				}}
				className={`${
					currentFontSize <= MIN_FONT
						? 'f-btn-decrease f-btn-disabled'
						: 'f-btn-decrease'
				}`}
			>
				A-
			</button>
			<button
				onClick={() => {
					handleChangeSize(currentFontSize + 2)
				}}
				className={`${
					currentFontSize >= MAX_FONT
						? 'f-btn-increase f-btn-disabled'
						: 'f-btn-increase'
				}`}
			>
				A+
			</button>
		</div>
	)
}
export default FontButtons
