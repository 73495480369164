import { axiosInstance } from './axiosInstance'
import * as constants from '../state/constants/apiConstants'

class ArticleService {
	static getAnomLikes() {
		return axiosInstance.get(constants.LIKES_ARTICLES).then(resp => resp.data)
	}

	static setAnomLikes(payload) {
		return axiosInstance
			.post(constants.SET_LIKE, payload)
			.then(resp => resp.data)
	}

	static setAnomUnLikes(payload) {
		return axiosInstance
			.put(constants.SET_UNLIKE, payload)
			.then(resp => resp.data)
	}

	static getAuthLikes(header) {
		return axiosInstance
			.get(constants.LIKES_ARTICLES, header)
			.then(resp => resp.data)
	}

	static setAuthLikes(payload, header) {
		return axiosInstance
			.post(constants.SET_LIKE, payload, header)
			.then(resp => resp.data)
	}

	static setAuthUnLikes(payload, header) {
		return axiosInstance
			.put(constants.SET_UNLIKE, payload, header)
			.then(resp => resp.data)
	}

	static validateEmailAndCell(payload) {
		return axiosInstance
			.post(constants.LEAFLET_VALIDATE, payload)
			.then(resp => resp.data)
	}

	static getComments(payload, config) {
		return axiosInstance.post(constants.TALK, payload, config)
	}
}

export default ArticleService
