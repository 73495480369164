import React from 'react'
import Button from '../../atoms/Button/Button'
import BtnWhatsapp from '../../atoms/btn-whatsapp/btn-whatsapp'
import RegisterModal from '../../organisms/register-modal/register-modal'
import useMobileMode from '../../../hooks/useMobileMode'
import {
	censorCellPhone,
	censorMail,
} from '../../../state/utils/utilities-string'
import './modal-user-registered.scss'

const ModalUserRegistered = ({
	dataUserRegistered,
	hideModal,
	onUpdateInformation,
}) => {
	const { name = '', email = '', cellPhone = '' } = dataUserRegistered
	const { isMobile } = useMobileMode()

	return (
		<RegisterModal
			title={`Hola ${name},`}
			description={
				<>
					Ya tienes una cuenta activa en nuestra comunidad TENA <br />
					con este correo electrónico <b>{censorMail(email)}</b> y este celular{' '}
					<b>{censorCellPhone(cellPhone)}</b> <br />
					Si quieres puedes actualizar tu información,{' '}
					<a href='/autenticacion/'>iniciar sesión</a> o volver a validar tus
					datos.
				</>
			}
			iconCheck
		>
			<div className='f-buttons-modal-redistered'>
				<Button
					type='button'
					classname='f-button f-button--secondary'
					text='ATRAS'
					onClickAction={hideModal}
				/>
				<Button
					type='button'
					classname='f-button f-button--primary'
					text='ACTUALIZAR INFORMACIÓN'
					onClickAction={onUpdateInformation}
				/>
				<BtnWhatsapp onClick={() => {
          if (!isMobile) {
            localStorage.setItem("dataUserRegisteredProspect", JSON.stringify(dataUserRegistered)); hideModal();
          }
        }} />
			</div>
		</RegisterModal>
	)
}

export default ModalUserRegistered
