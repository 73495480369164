import { graphql, useStaticQuery } from 'gatsby'

export default function usedocument() {
	const result = useStaticQuery(
		graphql`
			query IDENTIFICATION_DOCUMENT {
				allContentfulTenaRegistroV2(
					filter: { slug: { eq: "informacion-personal" } }
				) {
					nodes {
						node_locale
						inputNumeroDeDocumento
						inputTipoDeDocumento
						tipoDeDocumento {
							name
							value
						}
					}
				}
			}
		`
	)

	const resultFiltered = result.allContentfulTenaRegistroV2.nodes.filter(e =>
		e.node_locale
			.toLowerCase()
			.includes(process.env.LOCALE ? process.env.LOCALE.toLowerCase() : 'es-co')
	)[0]
	return resultFiltered
}
