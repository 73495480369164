import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
	Checkbox,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { INLINES } from '@contentful/rich-text-types'
import DataPolicy from '../data-policy/data-policy'
import ButtonLoading from '../buttonLoading/buttonLoading'
import Alert from '../../atoms/alert/alert'
import InputMaterial from '../../atoms/input-material/input-material'
import Icon from '../../atoms/Icon/Icon'
import Button from '../../atoms/Button/Button'
import ModalUserRegistered from '../modal-user-registered/modal-user-registered'
import ModalUserMigrated from '../modal-user-migrated/modal-user-migrated'
import GenericModalAlert from '../generic-modal-alert/generic-modal-alert'
import LayoutModal from '../../organisms/layoutModal/layout-modal'
import ArticleService from '../../../services/articles'
import RegisterService from '../../../services/register'
import useDocument from '../../../hooks/useDocument'
import useModalGeneric from '../../../hooks/useModalGeneric'
import { EMAIL_PATTERN } from '../../../state/constants/appConstants'
import useLocaleMode from '../../../hooks/useLocaleMode'
import {
	V_TYPE_ID,
	V_NUMBER_ID,
	V_EMAIL,
	V_CELLPHONE,
	V_NAME,
	V_LASTNAME,
} from '../../../state/constants/reactHookFormValidations'
import { secureStorage } from '../../../state/utils/utilities-secure-storage'
import useHaveSamples from '../../../hooks/useHaveSamples'
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'
import { dataLayerUserInfo } from '../../../utils/userInfo'
import './modal-register-prospect.scss'

// Para nosotros es muy importante tu opinión, déjanos tus datos.
const DEFAULT_COPIES = {
	title:
		'Si quieres estar al tanto de todos los contenidos que tenemos para ti, déjanos tus datos',
}

const ModalRegisterProspect = ({
	showMessageSuccess = false,
	hideAction = () => {},
	handleSuccessRegister,
	title = DEFAULT_COPIES.title,
	...props
}) => {
	const {
		allContentfulTenaAcademiaTena: { nodes },
	} = useStaticQuery(graphql`
		{
			allContentfulTenaAcademiaTena {
				nodes {
					node_locale
					checkLegales {
						idEtiqueta
						informacinPrincipal {
							json
						}
					}
				}
			}
		}
	`)
	const { locale } = useLocaleMode()
	const filterChecks =
		nodes.filter(node => node.node_locale === locale)[0]?.checkLegales || []
	const documentInfo = useDocument()
	const [isLoaderVisible, setLoaderVisible] = useState(false)
	const [generalError, setGeneralError] = useState('')

	const [isModal, showModal, hideModal] = useModalGeneric(false)
	const [dataUserMigrated, setDataMigrated] = useState()
	const [dataUserRegistered, setDataUserRegistered] = useState()
	const [isSuccessModal, setIsSuccessModal] = useState(false)
	const { haveSamples } = useHaveSamples()
	const noHaveDocument = ['es-PR']

	const {
		register,
		triggerValidation,
		control,
		getValues,
		setError,
		watch,
		formState,
		errors,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		defaultValues: {
			email:
				props.inputValue && EMAIL_PATTERN.test(props.inputValue)
					? props.inputValue
					: '',
			cellPhone:
				props.inputValue && !EMAIL_PATTERN.test(props.inputValue)
					? props.inputValue
					: '',
		},
	})

	const checkDocument = async () => {
		const { numberId, typeId } = watch(['numberId', 'typeId'])

		if (numberId && numberId !== '' && typeId && typeId !== '') {
			const documentData = {
				numberId,
				typeId,
				userId: null,
			}

			const res = await RegisterService.checkDocument(documentData)
			if (!res || res.status !== 200) {
				return validateErrors(res)
			} else {
				return true
			}
		}
	}

	const checkEmail = async () => {
		const email = watch('email')

		if (email && email !== '') {
			const res = await RegisterService.checkEmail({ email })
			if (!res || res.status !== 200) {
				return validateErrors(res)
			} else {
				return true
			}
		}
	}

	const checkCellPhone = async () => {
		const cellPhone = watch('cellPhone')

		if (cellPhone && cellPhone !== '') {
			const cellPhoneWithPrefix = process.env.COUNTRY_PHONE_PREFIX + cellPhone

			const res = await RegisterService.checkPhone({
				cellPhone: cellPhoneWithPrefix,
			})
			if (!res || res.status !== 200) {
				return validateErrors(res)
			} else {
				return true
			}
		}
	}

	const validateErrors = res => {
		const { errorCode, data } = res

		if (
			data &&
			(data.state === 'MIGRACION' ||
				data.state === 'TEMPORAL' ||
				data.state === 'ACTIVO') &&
			(errorCode === 'DEM050' ||
				errorCode === 'DEM012' ||
				errorCode === 'DEM011')
		) {
			const formData = getValues()
			const termsObject = {}
			filterChecks?.forEach(({ idEtiqueta }) => {
				termsObject[idEtiqueta] = formData[idEtiqueta] ? 1 : 0
			})

			if (data.state === 'ACTIVO') {
				showModal()
				setDataUserRegistered(data)
				return res.userMessage
			}

			if (data.state === 'MIGRACION' || data.state === 'TEMPORAL') {
				if (dataUserMigrated?.userId !== data.userId) {
					// If form data is diferent to user migrated data and service return other usermigrated, then set data new migrated
					if (
						dataUserMigrated?.email !== formData.email &&
						dataUserMigrated?.cellPhone !==
							process.env.COUNTRY_PHONE_PREFIX + formData.cellPhone &&
						(noHaveDocument.includes(locale) ||
							dataUserMigrated?.typeId !== formData.typeId ||
							dataUserMigrated?.numberId !== formData.numberId)
					) {
						setDataMigrated({ ...data, ...termsObject })
						formData.email && triggerValidation('email')
						formData.cellPhone && triggerValidation('cellPhone')
						formData.numberId && triggerValidation('numberId')
						return true
					} else {
						return res.userMessage
					}
				} else {
					setDataMigrated({ ...data, ...termsObject })
					return true
				}
			}
		} else {
			return res.userMessage
		}
	}

	const onUpdateUserRegistered = () => {
		secureStorage.setItem('userDataRegister', dataUserRegistered)
		window.location.href = '/registro/recuperar-cuenta'
	}

	const onUpdateUserMigration = () => {
		const dataForm = getValues()
		filterChecks?.forEach(({ idEtiqueta }) => {
			dataForm[idEtiqueta] = dataForm[idEtiqueta] ? 1 : 0
		})
		secureStorage.setItem('userDataRegister', {
			...dataUserMigrated,
			...dataForm,
			tycAccepted: 0,
		})
		window.location.href = '/registro/paso-2/'
	}


	const userInfo=dataLayerUserInfo()
	const dataLaterInscripcion = () => {
		let loc=''
		if (typeof window !== 'undefined') {
			loc= window.location.href
		}
		pushDataLayerEvent({event: 'Interacciones - Registro',
		category: 'inscripción',
		action: 'click',
		label: 'Finalizar',
		location: loc, 
		user_data: userInfo.user_data? userInfo.user_data: '',
		log_status: userInfo.log_status,})
	}

	let resSubmit
	// Handle send form
	const onSubmit = e => {
		e.preventDefault()
		dataLaterInscripcion()
		window.dataLayer = window.dataLayer || []
		const data = getValues()
		setLoaderVisible(true)

		const payload = {
			...data,
			cellPhone: process.env.COUNTRY_PHONE_PREFIX + data.cellPhone,
			countryRegisterId: process.env.COUNTRY_CODE,
			lastName: data.lastname,
			tycAccepted: 0,
			//   uytdAccepted: 0,
		}
		filterChecks?.forEach(({ idEtiqueta }) => {
			payload[idEtiqueta] = data[idEtiqueta] ? 1 : 0
		})

		ArticleService.validateEmailAndCell(payload)
			.then(resp => {
				if (resp.status === 200) {
					RegisterService.registerLeaflet(payload)
						.then(resp2 => {
							if (resp2.status === 200) {
								if (showMessageSuccess) {
									setIsSuccessModal(true)
									resSubmit = resp2.data
								} else {
									props.onSuccessRegister && props.onSuccessRegister(resp2.data)
									hideAction()
								}
							} else if (resp2.userMessage) {
								setGeneralError(resp2.userMessage)
							}
						})
						.finally(() => {
							setLoaderVisible(false)
						})
				} else {
					const {
						data,
						errorCode = '',
						userMessage = 'Ocurrio un error inesperado',
					} = resp
					if (
						(errorCode === 'DEM050' ||
							errorCode === 'DEM012' ||
							errorCode === 'DEM011') &&
						data?.userId === dataUserMigrated?.userId
					) {
						showModal()
					} else if (errorCode === 'DEM101') {
						setError('email', 'customError', userMessage)
					}

					setLoaderVisible(false)
				}
			})
			.catch(err => {
				console.log(err)
				setLoaderVisible(false)
			})
	}

	const dataLayerFormProspect = () => {
		pushDataLayerEvent({
			event: 'form_visible',
			form_name: 'Formulario inscripción contenido TENA',
			form_id: 'form-prospect'
		})
	}

	useEffect(() => {
	  dataLayerFormProspect()
	}, [])

	useEffect(() => {
		if (localStorage.getItem("dataUserRegisteredProspect")) {
			setDataUserRegistered(JSON.parse(localStorage.getItem("dataUserRegisteredProspect")))
			showModal()
			localStorage.removeItem("dataUserRegisteredProspect");
		}
	}, [])

	return (
		<LayoutModal onClose={hideAction}>
			<div className='f-modal-prospect'>
				<div className='f-modal-prospect__head'>
					<i
						className='far fa-times f-modal-prospect__head__icon'
						onClick={hideAction}
					></i>
				</div>
				<h3 className='f-modal-prospect__title'>{title}</h3>
				<form id='form-prospect' className='f-modal-prospect__form' onSubmit={onSubmit}>
					{!noHaveDocument.includes(process.env.LOCALE) && (
						<>
							<FormControl
								variant='outlined'
								className='f-select-input f-modal-prospect__form__select'
								error={errors.typeId != null}
							>
								<InputLabel htmlFor='outlined-gender-simple'>
									{documentInfo.inputTipoDeDocumento}
								</InputLabel>
								<Controller
									as={
										<Select
											MenuProps={{
												disableScrollLock: true,
											}}
										>
											{documentInfo.tipoDeDocumento.map((option, index) => (
												<MenuItem value={option.value} key={index}>
													{option.name}
												</MenuItem>
											))}
										</Select>
									}
									rules={{
										...V_TYPE_ID,
										validate: async () => {
											const numberId = watch('numberId')
											if (numberId && numberId !== '' && numberId.length > 5) {
												triggerValidation('numberId')
											}
										},
									}}
									defaultValue=''
									name='typeId'
									control={control}
								/>
								<Icon
									icon='icon-chevron-down lonchmara'
									size='0-7'
									tagtype='i'
								/>
							</FormControl>
							<InputMaterial
								errors={errors}
								showMessageError={false}
								name='numberId'
								type='text'
								classes='f-modal-prospect__form__input'
								label={documentInfo?.inputNumeroDeDocumento}
								register={register({
									...V_NUMBER_ID,
									validate: async () => await checkDocument(),
								})}
							/>
						</>
					)}

					<InputMaterial
						errors={errors}
						showMessageError={false}
						name='email'
						type='text'
						classes='f-modal-prospect__form__input'
						label='Correo electrónico *'
						register={register({
							...V_EMAIL,
							validate: async () => await checkEmail(),
						})}
					/>

					<InputMaterial
						errors={errors}
						showMessageError={false}
						name='cellPhone'
						type='text'
						classes='f-modal-prospect__form__input'
						label='Celular *'
						register={register({
							...V_CELLPHONE,
							validate: async () => await checkCellPhone(),
						})}
					/>

					<InputMaterial
						errors={errors}
						showMessageError={false}
						name='name'
						type='text'
						classes='f-modal-prospect__form__input'
						label='Nombres *'
						register={register({
							...V_NAME,
						})}
					/>

					<InputMaterial
						errors={errors}
						showMessageError={false}
						name='lastname'
						type='text'
						classes='f-modal-prospect__form__input'
						label='Apellidos *'
						register={register({
							...V_LASTNAME,
						})}
					/>

					<div className='f-modal-prospect__form__terms'>
						{filterChecks?.map(({ idEtiqueta, informacinPrincipal }, index) => {
							return (
								<div className='f-terms-wrapper' key={index}>
									<Checkbox
										inputProps={{ name: idEtiqueta }}
										onChange={() => triggerValidation(idEtiqueta)}
										inputRef={register({
											required: 'Debes aceptar términos y condiciones',
										})}
										className={`f-terms-checkbox ${
											errors[idEtiqueta] ? 'f-error' : ''
										}`}
									/>
									<div className='f-terms'>
										{documentToReactComponents(informacinPrincipal.json, {
											renderNode: {
												[INLINES.HYPERLINK]: ({ data: { uri } }, content) => (
													<a target='_blank' href={uri} rel='noreferrer'>
														{' '}
														{content}
													</a>
												),
											},
										})}
									</div>
								</div>
							)
						})}
					</div>

					<div className='f-modal-prospect__form__data-policy'>
						<DataPolicy />
					</div>

					{(Object.keys(errors).length !== 0 || generalError) && (
						<Alert
							alertType='error-leaflet'
							description={
								errors.typeId?.message ||
								errors.numberId?.message ||
								errors.email?.message ||
								errors.cellPhone?.message ||
								errors.name?.message ||
								errors.lastname?.message ||
								generalError ||
								''
							}
						/>
					)}

					<div className='f-modal-prospect__form__button'>
						<ButtonLoading
							button={
								<Button
									type='input'
									classname='f-button f-button--primary'
									text={props.buttonProspect}
								/>
							}
							isEnabled={!formState.isValid}
							isShowingLoader={isLoaderVisible}
							bgClass='primary'
						/>
					</div>
				</form>
			</div>

			{isModal &&
				(dataUserRegistered?.state === 'ACTIVO' ? (
					<ModalUserRegistered
						dataUserRegistered={dataUserRegistered}
						hideModal={() => {
							setDataUserRegistered(null)
							hideModal()
						}}
						onUpdateInformation={onUpdateUserRegistered}
					/>
				) : dataUserMigrated?.state === 'MIGRACION' ||
				  dataUserMigrated?.state === 'TEMPORAL' ? (
					<ModalUserMigrated
						dataUserMigrated={dataUserMigrated}
						hideModal={hideModal}
						onUpdateInformation={onUpdateUserMigration}
					/>
				) : null)}

			{isSuccessModal && (
				<GenericModalAlert
					descriptionRender={
						props.modalSuccessInfo ? (
							documentToReactComponents(props.modalSuccessInfo.json)
						) : (
							<>
								<h2 className='f-h2'>Inscripción exitosa al contenido TENA</h2>
								<p>
									¿Ya conoces todos nuestros productos?
									<br />
									Tenemos uno para cada necesidad{' '}
									{haveSamples ? (
										<>
											, pide una muestra gratis{' '}
										</>
									) : (
										<></>
									)}
								</p>
							</>
						)
					}
					handleAction={() => {
						setIsSuccessModal(false)
						props.isFromEvent ?	props.onSuccessRegister(resSubmit) : hideAction()
					}}
					modifier={ props.modalSuccessInfo ? "" : "prospect"}
					type='primary'
				/>
			)}
		</LayoutModal>
	)
}

export default ModalRegisterProspect
