export const replaceAccent = (str = '') => {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const jsUpperCasefirst = (value = '') => {
	if (typeof value === 'string') {
		const valueLowered = value.toLocaleLowerCase()
		return valueLowered.charAt(0).toUpperCase() + valueLowered.slice(1)
	} else {
		return value
	}
}

export const censorCellPhone = cellphone => {
	const phonePrefixLength = process.env.COUNTRY_PHONE_PREFIX.length || 3
	const cellWithOutPrefix =
		cellphone.length > phonePrefixLength
			? cellphone.substr(phonePrefixLength)
			: cellphone
	return '*'.repeat(cellWithOutPrefix.length - 4) + cellWithOutPrefix.substr(-4)
}

export const censorMail = email => {
	const arr = email.split('@')
	const emailLengh = arr[0].length
	const censoredMail = '*'.repeat(emailLengh === 1 ? 2 : emailLengh - 2)
	return (
		email.substring(0, emailLengh === 1 ? 1 : 2) + censoredMail + '@' + arr[1]
	)
}
