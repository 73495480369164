import React from 'react'
import useBrowserMode from '../../../hooks/useBrowserMode'
import ReactDOM from 'react-dom'

const LayoutPortal = ({
	children,
	className = 'modal-portal',
	element = 'div',
}) => {
	const { isBrowser } = useBrowserMode()
	if (!isBrowser) return false

	const [container] = React.useState(() => {
		const el = document.createElement(element)
		el.classList.add(className)
		return el
	})

	React.useEffect(() => {
		document.body.appendChild(container)
		return () => {
			document.body.removeChild(container)
		}
	}, [])

	return ReactDOM.createPortal(children, container)
}

export default LayoutPortal
