import React from 'react'
import './btn-whatsapp.scss'
import ImgWhatsApp from '../../../assets/images/whatsapp-icon.png'
import { useStaticQuery, graphql } from 'gatsby'
import useMobileMode from '../../../hooks/useMobileMode'
import useLocaleMode from '../../../hooks/useLocaleMode'

const BtnWhatsapp = props => {
	const { locale } = useLocaleMode()
	const query = useStaticQuery(graphql`
		query SLUG_WHATSAPP {
			allContentfulTenaOpcionesMenu(
				filter: { titulo: { eq: "Megamenú > Asesoría" } }
			) {
				nodes {
					slug
					node_locale
				}
			}
		}
	`)

	const whatsInfo =
		query &&
		query.allContentfulTenaOpcionesMenu.nodes.filter(
			node => node.node_locale === locale
		)[0]
	const { isMobile } = useMobileMode()

	const validateIsWhatsApp = e => {
		if (props.onClick) {
			props.onClick(e)
		}

		if (!isMobile) {
			e.preventDefault()
			window.location.hash = 'asesoria-whatsapp'
		}
	}

	return (
		<a
			className={`a-whatsapp ${props.className}`}
			href={whatsInfo.slug.replace('+', '')}
			target='__blank'
			onClick={validateIsWhatsApp}
		>
			¿Necesitas ayuda con tu registro?
			<img src={ImgWhatsApp} alt='' />
		</a>
	)
}

export default BtnWhatsapp
